import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { quotes, endorsements } from '../../../constants/apiconstants'
import { revertAll } from '@pages/feature/common/commonAction'
// import produce from "immer";

const authState = {
  operatorList: [],
  operatorPage: 0,
  exposures: '',
  recentFieldGroupLocator: '',
  assignOperatorToVehicle: [],
  operatorCount: 0,
  saveSubmitDataPage1: '',
  saveSubmitDataPage2: '',
  saveSubmitDataPage4: '',
  removeLocator: '',
  modalFlag: '',
  editMode: false,
  addMode: false,
  addModeFinal: false,
  addOperatorArray: [],
  isLoading: false,
  isError: '',
  updated: '',
  endorsementUpdated: '',
  isLoading1: false,
  isError1: '',
  operatorEnodorsedData: '',
  operatorEndorsementStatus: false,
  endorsedOperator: [],
  oldPolicyLevelOperatorList: [],
  removedEndorsedOperatorList: [],
  endorsedOperatorList: [],
  endorsedVehicle: []
}

export const operatordetailsSlice = createSlice({
  name: 'operatordetails',
  initialState: authState,
  reducers: {
    setPolicyLevelOperatorList: (state, { payload }) => ({
      ...state,
      oldPolicyLevelOperatorList: payload
    }),
    setOperatorPage: (state, { payload }) => ({
      ...state,
      operatorPage: payload
    }),

    setEndorsementUpdated: (state, { payload }) => ({
      ...state,
      endorsementUpdated: payload
    }),
    setRemovedEndorsedOperatorList: (state, { payload }) => ({
      ...state,
      removedEndorsedOperatorList: payload
    }),
    setAddModeFinal: (state, { payload }) => ({
      ...state,
      addModeFinal: payload
    }),
    setModalFlag: (state, { payload }) => ({
      ...state,
      modalFlag: payload
    }),
    setAddOperatorArray: (state, { payload }) => ({
      ...state,
      addOperatorArray: payload
    }),
    setEndorsedVehicleInOperator: (state, { payload }) => ({
      ...state,
      endorsedVehicle: payload
    }),
    setEndorsedOperatorList: (state, { payload }) => ({
      ...state,
      endorsedOperatorList: payload
    }),
    setEndorsedOperator: (state, { payload }) => ({
      ...state,
      endorsedOperator: payload
    }),

    setOperatorCount: (state, { payload }) => ({
      ...state,
      operatorCount: payload
    }),
    setOperatorEndorsementStatus: (state, { payload }) => ({
      ...state,
      operatorEndorsementStatus: payload
    }),

    incrementOperatorCount: (state) => ({
      ...state,
      operatorCount: state.operatorCount + 1
    }),

    operatorDetails: (state, { payload }) => ({
      ...state,
      operatorList: payload
    }),
    updateExposureDetails: (state, { payload }) => ({
      ...state,
      exposures: payload
    }),

    setFieldGroupLocator: (state, { payload }) => ({
      ...state,
      recentFieldGroupLocator: payload
    }),

    setEditMode: (state, { payload }) => ({
      ...state,
      editMode: payload
    }),

    setAddMode: (state, { payload }) => ({
      ...state,
      addMode: payload
    }),
    setRemoveLocator: (state, { payload }) => ({
      ...state,
      removeLocator: payload.item
    }),
    setOperatorToVehicle: (state, { payload }) => ({
      ...state,
      assignOperatorToVehicle: payload
    }),
    setSubmitDataPage1: (state, { payload }) => ({
      ...state,
      saveSubmitDataPage1: payload
    }),

    setSubmitDataPage2: (state, { payload }) => ({
      ...state,
      saveSubmitDataPage2: payload
    }),
    setSubmitDataPage4: (state, { payload }) => ({
      ...state,
      saveSubmitDataPage4: payload
    }),
    updateResponse: (state, { payload }) => ({
      ...state,
      updated: payload
    })
  },
  extraReducers: (builder) => {
    builder.addCase(revertAll, (state, action) => authState)
    builder.addCase(updateQuoteOperatorLevel.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(updateQuoteOperatorLevel.rejected, (state, action) => {
      state.isLoading = 'Rejected'
      state.isError = action.error.message
    })
    builder.addCase(updateQuoteOperatorLevel.fulfilled, (state, action) => {
      state.isLoading = 'Complete'
      state.updated = action.payload.characteristics
      state.exposures = action.payload.exposures

      const operator = action.payload?.characteristics?.fieldValues?.operator
      if (operator?.length) {
        if (state.editMode === false) {
          state.recentFieldGroupLocator = operator[operator.length - 1]
        }
        state.operatorList = operator
        state.operatorCount = operator.length
      }
    })
    builder.addCase(removeField.pending, (state, action) => {
      state.isLoading1 = true
    })
    builder.addCase(removeField.rejected, (state, action) => {
      state.isLoading1 = 'Rejected'
      state.isError1 = action.error.message
    })
    builder.addCase(removeField.fulfilled, (state, action) => {
      state.isLoading1 = 'Complete'
      state.updated = action.payload.characteristics
      state.exposures = action.payload.exposures
      console.log('removeField.fulfilled editMode', state.editMode)
      console.log(
        'removeField.fulfilled fieldValues',
        action.payload?.characteristics?.fieldValues
      )
      const operator = action.payload?.characteristics?.fieldValues?.operator
      if (operator?.length) {
        state.recentFieldGroupLocator = operator[operator.length - 1]
        state.operatorList = operator
        state.operatorCount = operator.length
      }
    })
    builder.addCase(addPerils.pending, (state, action) => {
      state.isLoading = 'Pending'
    })
    builder.addCase(addPerils.rejected, (state, action) => {
      state.isLoading = 'Rejected'
      state.isError = action.error.message
    })
    builder.addCase(addPerils.fulfilled, (state, action) => {
      state.isLoading = 'Complete'
      if (!(parseInt(action.payload.status) >= 400 || action.payload.message)) {
        state.updated = action.payload.characteristics
        state.exposures = action.payload.exposures
      }
    })
    builder.addCase(operatorUpdateEndorsement.pending, (state, action) => {
      state.isLoading = 'Pending'
    })
    builder.addCase(operatorUpdateEndorsement.rejected, (state, action) => {
      state.isLoading = 'Rejected'
      state.isError = action.error.message
    })
    builder.addCase(operatorUpdateEndorsement.fulfilled, (state, action) => {
      state.isLoading = 'Complete'
      if (!(parseInt(action.payload.status) >= 400 || action.payload.message)) {
        state.endorsementUpdated = action.payload
      }
    })
  }
})

export const updateQuoteOperatorLevel = createAsyncThunk(
  'updateQuoteOperatorApi',
  async (params, thunkAPI) => {
    let result = await fetch('/api/usercontroller/quote/updatequote', {
      method: 'POST',
      body: JSON.stringify({ payload: params.body, locator: params.locator }),
      headers: {
        // Authorization: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error(error)
        return error
      })

    return result
  }
)

export const removeField = createAsyncThunk(
  'removeFieldLocatorApi',
  async (params, thunkAPI) => {
    let check1 = fetch('/api/usercontroller/quote/updatequote', {
      method: 'POST',
      body: JSON.stringify({ payload: params.body, locator: params.locator }),
      headers: {
        // Authorization: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error(error)
        return error
      })

    return check1
  }
)

export const operatorUpdateEndorsement = createAsyncThunk(
  'operatorUpdateEndorsementApi',
  async (params, thunkAPI) => {
    let check1 = fetch(
      '/api/usercontroller/endorsement/updateendorsement',

      {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
          // Authorization: params.auth?.authorizationToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Accept-Encoding':
            'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error(error)
        return error
      })

    return check1
  }
)

export const priceEndorsement = createAsyncThunk(
  'priceEndorsementApi',
  async (params, thunkAPI) => {
    let check1 = fetch('/api/usercontroller/endorsement/priceendorsement', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        // Authorization: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error(error)
        return error
      })

    return check1
  }
)

export const fetchPriceEndorsement = createAsyncThunk(
  'fetchPriceEndorsementApi',
  async (params, thunkAPI) => {
    let check1 = fetch('/api/usercontroller/endorsement/fetchendorsement', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        // Authorization: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error(error)
        return error
      })

    return check1
  }
)

export const acceptEndorsement = createAsyncThunk(
  'acceptEndorsementApi',
  async (params, thunkAPI) => {
    let check1 = fetch('/api/usercontroller/endorsement/acceptendorsement', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        // Authorization: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error(error)
        return error
      })

    return check1
  }
)

export const quoteEndorsement = createAsyncThunk(
  'quoteEndorsementApi',
  async (params, thunkAPI) => {
    let check1 = fetch('/api/usercontroller/endorsement/quoteendorsement', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        // Authorization: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error(error)
        return error
      })

    return check1
  }
)

export const addPerils = createAsyncThunk(
  'addPerils',
  async (params, thunkAPI) => {
    let valid = await fetch('/api/usercontroller/quote/updatequote', {
      method: 'POST',
      body: JSON.stringify({
        payload: params.body,
        locator: params.quotelocator
      }),
      headers: {
        // auth: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error(error)
        return error
      })
    return valid
  }
)

export const {
  setOperatorPage,
  setOperatorCount,
  incrementOperatorCount,
  operatorDetails,
  setFieldGroupLocator,
  setEditMode,
  setAddMode,
  setupdate,
  setRemoveLocator,
  setOperatorToVehicle,
  updateExposureDetails,
  updateResponse,
  setSubmitDataPage1,
  setSubmitDataPage2,
  setSubmitDataPage4,
  setAddModeFinal,
  setAddOperatorArray,
  setModalFlag,
  setOperatorEndorsementStatus,
  setEndorsedOperator,
  setEndorsedVehicleInOperator,
  setEndorsedOperatorList,
  setEndorsementUpdated,
  setPolicyLevelOperatorList,
  setRemovedEndorsedOperatorList
} = operatordetailsSlice.actions

export const operatordetailsReducer = operatordetailsSlice.reducer
