import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  createPolicy,
  quotes,
  policyholder,
  checkInvoices
} from '../../../constants/apiconstants'
import { revertAll } from '@pages/feature/common/commonAction'
import { getSession } from '@utils/auth'

const authState = {
  isLoading: false,
  isError: '',
  getData: '',
  documents: '',
  pageError: 'false',
  policyHolderData: '',
  communicationEmail: false,
  communicationPhone: false,
  communicationPush: false,
  newsEmail: false,
  newsPhone: false,
  newsNotification: false,
  cancellationLocator: '',
  cancellationStatus: ''
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: authState,

  reducers: {
    setGetData: (state, { payload }) => ({
      ...state,
      getData: payload
    }),
    setDocuments: (state, { payload }) => ({
      ...state,
      documents: payload
    }),
    setPageError: (state, { payload }) => ({
      ...state,
      pageError: payload
    }),
    setCommunicationEmail: (state, { payload }) => ({
      ...state,
      communicationEmail: payload
    }),
    setCommunicationPhone: (state, { payload }) => ({
      ...state,
      communicationPhone: payload
    }),
    setCommunicationPush: (state, { payload }) => ({
      ...state,
      communicationPush: payload
    }),
    setNewsEmail: (state, { payload }) => ({
      ...state,
      newsEmail: payload
    }),
    setNewsPhone: (state, { payload }) => ({
      ...state,
      newsPhone: payload
    }),
    setNewsNotification: (state, { payload }) => ({
      ...state,
      newsNotification: payload
    }),
    setCancellationLocator: (state, { payload }) => ({
      ...state,
      cancellationLocator: payload
    }),
    setCancellationStatus: (state, { payload }) => ({
      ...state,
      cancellationStatus: payload
    })
  },
  extraReducers: (builder) => {
    builder.addCase(revertAll, (state, action) => authState)
    builder.addCase(fetchPolicy.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchPolicy.rejected, (state, action) => {
      state.isLoading = 'Rejected'
      state.isError = action.error.message
    })
    builder.addCase(fetchPolicy.fulfilled, (state, action) => {
      state.isLoading = 'Complete'
    })

    builder.addCase(fetchPaymentInvoicePolicy.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchPaymentInvoicePolicy.rejected, (state, action) => {
      state.isLoading = 'Rejected'
      state.isError = action.error.message
    })
    builder.addCase(fetchPaymentInvoicePolicy.fulfilled, (state, action) => {
      state.isLoading = 'Complete'
    })
    builder.addCase(fetchPolicyHolder.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchPolicyHolder.rejected, (state, action) => {
      state.isLoading = 'Rejected'
      state.isError = action.error.message
    })
    builder.addCase(fetchPolicyHolder.fulfilled, (state, action) => {
      state.isLoading = 'Complete'
      state.policyHolderData = action.payload
    })
  }
})

export const fetchPolicyLocator = createAsyncThunk(
  'fetchPolicyLocatorApi',
  async (params, thunkAPI) => {
    let check = fetch(
      '/api/usercontroller/quote/fetchpolicylocator',

      {
        method: 'POST',
        body: JSON.stringify({ params }),
        headers: {
          'Authorization': `Bearer ${params.token || ''}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Accept-Encoding':
            'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => data)

      .then((error) => error)
    return check
  }
)

export const fetchPolicy = createAsyncThunk(
  'fetchPolicyApi',
  async (params, thunkAPI) => {
    let check = fetch(
      '/api/usercontroller/quote/fetchpolicy',

      {
        method: 'POST',
        body: JSON.stringify({ locator: params.locator }),
        headers: {
          // auth: params.auth.authorizationToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Accept-Encoding':
            'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => data)

      .then((error) => error)
    return check
  }
)

export const fetchPaymentInvoicePolicy = createAsyncThunk(
  'fetchPaymentInvoicePolicyApi',
  async (params, thunkAPI) => {
    let check = fetch(
      '/api/usercontroller/quote/checkinvoices',

      {
        method: 'POST',
        body: JSON.stringify({ locator: params.locator }),
        headers: {
          // auth: params.auth.authorizationToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Accept-Encoding':
            'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => data)

      .then((error) => error)
    return check
  }
)

export const fetchPolicyHolder = createAsyncThunk(
  'fetchPolicyHolderApi',
  async (params, thunkAPI) => {
    let check = fetch(
      '/api/usercontroller/quote/fetchpolicyholder',

      {
        method: 'POST',
        body: JSON.stringify({ locator: params.locator }),
        headers: {
          // auth: params.auth.authorizationToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Accept-Encoding':
            'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => data)

      .then((error) => error)
    return check
  }
)

export const createCancellation = createAsyncThunk(
  'fetchPolicyHolderApi',
  async (params, thunkAPI) => {
    let check = fetch(
      '/api/usercontroller/cancellation/createcancellation',

      {
        method: 'POST',
        body: JSON.stringify({ payload: params.body, locator: params.locator }),
        headers: {
          // auth: params.auth.authorizationToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Accept-Encoding':
            'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => data)

      .then((error) => error)
    return check
  }
)

export const fetchCancellation = createAsyncThunk(
  'fetchPolicyHolderApi',
  async (params, thunkAPI) => {
    let check = fetch('/api/usercontroller/cancellation/fetchcancellation', {
      method: 'POST',
      body: JSON.stringify({ locator: params.locator }),
      headers: {
        // auth: params.auth.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)

      .then((error) => error)
    return check
  }
)

export const fetchAllCancellations = createAsyncThunk(
  'fetchPolicyHolderApi',
  async (params, thunkAPI) => {
    let check = fetch('/api/usercontroller/cancellation/getcancellations', {
      method: 'POST',
      body: JSON.stringify({ locator: params.locator }),
      headers: {
        // auth: params.auth.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)

      .then((error) => error)
    return check
  }
)

export const fetchAllEndorsement = createAsyncThunk(
  'fetchAllEndorsementApi',
  async (params, thunkAPI) => {
    let check = await fetch(
      '/api/usercontroller/endorsement/fetchallendorsement',
      {
        method: 'POST',
        body: JSON.stringify({ locator: params.locator }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Accept-Encoding':
            'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => data)
      .then((error) => error)
    return check
  }
)

export const fetchLatestRenewal = createAsyncThunk(
  'fetchLatestRenewalApi',
  async (params, thunkAPI) => {
    let check = await fetch(
      '/api/usercontroller/renewal/fetchlatestrenewal',
      {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Accept-Encoding':
            'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => data)
      .then((error) => error)
    return check
  }
)

export const {
  setGetData,
  setDocuments,
  setPageError,
  setCommunicationEmail,
  setCommunicationPhone,
  setCommunicationPush,
  setNewsEmail,
  setNewsPhone,
  setNewsNotification,
  setCancellationLocator,
  setCancellationStatus
} = dashboardSlice.actions

export const dashboardReducer = dashboardSlice.reducer
