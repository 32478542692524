import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { quotes, policyholder } from '../../../constants/apiconstants'
import { revertAll } from '@pages/feature/common/commonAction'
// create a slice function with name, initial State and reducer function.

const authState = {
  policyData: '',
  addressResponse: '',
  savedData: '',
  loading: false,
  error: '',
  responseData: '',
  data: ''
}

export const aboutyouSlice = createSlice({
  name: 'aboutYou',
  initialState: authState,
  reducers: {
    onSubmit: (state, { payload }) => ({
      ...state,
      savedData: payload
    }),
    reset: (state, { payload }) => ({
      ...state,
      policyData: '',
      savedData: '',
      loading: false,
      error: '',
      data: ''
    }),
    setAddressCheck: (state, { payload }) => ({
      ...state,
      addressResponse: payload
    }),
    setResponseData: (state, { payload }) => ({
      ...state,
      responseData: payload
    })
  },
  extraReducers: (builder) => {
    builder.addCase(revertAll, (state, action) => authState)
    builder.addCase(fetchQuoteLevel.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(fetchQuoteLevel.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(fetchQuoteLevel.fulfilled, (state, action) => {
      state.loading = 'Complete'
      state.policyData = action.payload
    })
    builder.addCase(setPolicyHolderLevel.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(setPolicyHolderLevel.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(setPolicyHolderLevel.fulfilled, (state, action) => {
      state.loading = 'Complete'
      state.reposoneData = action.payload
      // state.version =
    })
  }
})

export const fetchQuoteLevel = createAsyncThunk(
  'FetchQuoteApi',
  async (params, thunkAPI) => {
    let valid = await fetch('/api/usercontroller/quote/fetchquote', {
      method: 'POST',
      body: JSON.stringify({ locator: params.locator }),
      headers: {
        // auth: params.auth.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    }).then((response) => response.json())
    return {
      territory_address1:
        valid.exposures[0].characteristics[0].fieldValues.territory_address1[0],
      territory_address2:
        valid.exposures[0].characteristics[0].fieldValues.territory_address2[0],
      territory_city:
        valid.exposures[0].characteristics[0].fieldValues.territory_city[0],
      territory_state:
        valid.exposures[0].characteristics[0].fieldValues.territory_state[0],
      territory_postalcode:
        valid.exposures[0].characteristics[0].fieldValues
          .territory_postalcode[0]
    }
  }
)
export const setPolicyHolderLevel = createAsyncThunk(
  'SetPolicyHolderApi',
  async (params, thunkAPI) => {
    let valid = await fetch('/api/usercontroller/quote/updatepolicyholder', {
      method: 'POST',
      body: JSON.stringify(params.body),
      headers: {
        // auth: params.auth.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    }).then((response) => response.json())
    return valid
  }
)

export const { onSubmit, setAddressCheck, setResponseData } =
  aboutyouSlice.actions

export const aboutyouReducer = aboutyouSlice.reducer
