export const authUrl = '/account/authenticate'
export const createPolicyHolder = '/policyholder/create'
export const PolicyHolderUpdate = '/policyholder/update'
export const createPolicy = '/policy'
export const checkInvoices = '/checkInvoices'
export const auxDataUrl = '/session/'
export const quotes = '/quotes'
export const policyholder = '/policyholder/'
export const policies = '/policies/'
export const policy = '/policy/'
export const price = '/price'
export const update = '/update/'
export const invalidate = '/invalidate'
export const issue = '/issue'
export const endorsements = '/endorsements'
export const endorsementsIssued = '/integration-api/ff/endorsement/v1/create'
export const stepper = '/stepper'
export const zipcodevalidation =
  '/variable/v1/tabularDatasources/query/zipcode_state_table'
export const zipcode = '/zipcode/'
export const limits = '/limits'
export const selectQuote = '/select'
export const quote = '/quote'
export const accept = '/accept'
export const lexisnexis = '/integration-api/lexis/ncf/v1/fetch'
export const lexisnexisendorsement =
  '/integration-api/lexis/ncf/v1/endorsement/fetch'
export const createNew = '/createNew'
export const issued = '/issued'
export const currentPolicy = 'currentPolicy'
export const ffcreate = '/integration-api/ff/policy/v1/create'
export const ffpayment = '/integration-api/ff/pay/v1/create'
export const signUrl = '/integration-api/esign/signingurl/v1/create'
export const downloadUrl = '/integration-api/esign/downloadurl/v1/create'
export const outstanding_Balance = '/outstanding_balance'
export const nextDue = '/next_due'
export const cancellations = '/cancellations'
export const create_contact = '/hubspot/create-contact'
export const getPolicyLocator = '/get-customer-policy-locator'
export const renewals = '/renewals'